define(['siteObj', 'componentHelper', 'app'], function (siteObj, componentHelper, app) {
  const outOfStockNotification = () => {
    const REGEX = (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/);
    const RECAPTCHA_URL = "https://www.recaptcha.net/recaptcha/api.js?hl=";

    const component = {};
    component.config = {
      selectors: {
        form: '.outOfStockNotification_signUpForm',
        email: '#customerEmail',
        submitButton: '.outOfStockNotification_signUpButton',
        checkbox: '#marketingComms',
        emailValidation: '.alert-invalid',
        checkboxValidation: '.alert-checkbox',
        mainContainer: '.outOfStockNotification_signUpContainer',
        thankYouContainer: '.outOfStockNotification_signUp_result_success',
        failureContainer: '.outOfStockNotification_signUp_result_failure',
        requiresRecaptcha: '.outOfStockNotification_signUp_result_requires_recaptcha',
        recaptchaContainer: '#outOfStockNotification_captcha',
        productAddToBasketButton: '[data-component=productAddToBasket]',
      },
      attribs: {
        disabled: 'disabled',
        siteKey: 'data-sitekey',
        productId: 'data-product-id',
        languageCode: 'data-lang-code',
      },
      channels: {
        update: 'outOfStockNotification/update'
      }, 
      validation: {
        email: false,
        checkbox: true
      }
    }

    component.init = (element, userDriven, productId) => {
      component.element = element;
      component.email = component.element.querySelector(component.config.selectors.email);
      component.submitButton = component.element.querySelector(component.config.selectors.submitButton);
      component.checkbox = component.element.querySelector(component.config.selectors.checkbox);
      component.emailValidation = component.element.querySelector(component.config.selectors.emailValidation);
      component.checkboxValidation = component.element.querySelector(component.config.selectors.checkboxValidation);
      component.mainContainer = component.element.querySelector(component.config.selectors.mainContainer);
      component.thankYouContainer = component.element.querySelector(component.config.selectors.thankYouContainer);
      component.failureContainer = component.element.querySelector(component.config.selectors.failureContainer);
      component.requiresRecaptcha = component.element.querySelector(component.config.selectors.requiresRecaptcha);
      component.recaptchaContainer = component.element.querySelector(component.config.selectors.recaptchaContainer);
      component.languageCode = component.recaptchaContainer && component.recaptchaContainer.getAttribute(component.config.attribs.languageCode) || "en";
      component.addToBasketButton = document.querySelector(component.config.selectors.productAddToBasketButton);
      component.productId = productId ? productId : component.element && component.element.getAttribute(component.config.attribs.productId);

      component.bind();
      if (!userDriven) {
        component.subscribe();
      }
      return component;
    };

    component.bind = () => {
      component.email && component.email.addEventListener('change', (event) => {
        REGEX.test(event.target.value)
          ? component.validator('email', true)
          : component.validator('email', false);
      })

      component.checkbox && component.checkbox.addEventListener('change', () => {
        component.validator('checkbox', component.checkbox.checked)
      })

      component.element && component.element.addEventListener("click", component.loadCaptcha);
      component.submitButton && component.submitButton.addEventListener('click', component.processPostBody);
    }

    component.subscribe = () => {
      app.subscribe(component.config.channels.update, component.loadOutOfStockNotification);
    }

    component.validator = (input, bool) => {
      const display = bool ? 'none' : 'block';

      component.config.validation[input] = bool
      component[[input]+'Validation'].style.display = display

      !!component.config.validation.checkbox && !!component.config.validation.email
        ? component.submitButton.removeAttribute(component.config.attribs.disabled)
        : component.submitButton.setAttribute(component.config.attribs.disabled, true);
    }

    component.loadCaptcha = () => {
      //if #out-of-stock-notification is present on submit button we know its invis recaptcha
      component.submitButton.id === "#out-of-stock-notification"
        ? component.loadInvisibleReCaptcha()
        : component.loadRecaptcha();
    };

    // need to check this file that things exist first
    component.loadRecaptcha = () => {
      let siteKey = document.createElement("div");
      component.addGRecaptcha(siteKey);
      siteKey.setAttribute(component.config.attribs.siteKey, siteObj.captcha.siteKey);
      component.recaptchaContainer.insertAdjacentElement('afterbegin', siteKey);
      component.loadRecaptchaScript(false);
    };

    component.loadInvisibleReCaptcha = () => {
      component.addGRecaptcha(component.submitButton);
      component.loadRecaptchaScript();
    };

    component.loadRecaptchaScript = (invisible = true) => {
      let captchaScript = document.createElement("script");
      captchaScript.src = RECAPTCHA_URL + component.languageCode;
      captchaScript.type = "text/javascript";
      captchaScript.async = true;
      !invisible && captchaScript.classList.add("recaptchaScript");
      component.recaptchaContainer.insertAdjacentElement('afterbegin', captchaScript);
      component.element.removeEventListener("click", component.loadCaptcha);
    }

    component.processPostBody = (e) => {
      e.preventDefault();
      const recaptchaResponse = component.RecaptchaValid();
      if (recaptchaResponse.valid) {
        const sendData = {
          email: component.email.value,
          productId: component.productId,
          googleCaptchaToken: recaptchaResponse.response
        }
        component.sendAjaxPost(sendData);
      }
    }

    component.RecaptchaValid = () => {
      let recaptchaResponse = {
        valid: false,
        response: ''
      };

      let GOOGLE_RECAPTCHA_RESPONSE = component.element.querySelector('#g-recaptcha-response');
      if(!GOOGLE_RECAPTCHA_RESPONSE) {
        component.requiresRecaptcha.classList.remove('hide');
        return recaptchaResponse;
      }

      GOOGLE_RECAPTCHA_RESPONSE = GOOGLE_RECAPTCHA_RESPONSE.value;
      if (!GOOGLE_RECAPTCHA_RESPONSE) {
        component.requiresRecaptcha.classList.remove('hide');
        return recaptchaResponse;
      }

      if (!component.requiresRecaptcha.classList.contains('hide')) {
        component.requiresRecaptcha.classList.add('hide');
      }

      recaptchaResponse.valid = true;
      recaptchaResponse.response = GOOGLE_RECAPTCHA_RESPONSE;
      return recaptchaResponse;
    }

    component.sendAjaxPost = (sendData) => {
      app.ajax.post({
        url: '/waitlistSignUp.account',
        send: JSON.stringify(sendData),
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: component.successHandler,
        error: component.errorHandler
      });
    }

    component.successHandler = (responseData) => {
      responseData = JSON.parse(responseData ? responseData : '{}');
      const signUpStatus = responseData['response'];
      if (signUpStatus === 'PASS') {
        component.showSuccessContainer();
      } else {
        component.errorHandler();
      }
    }

    component.showSuccessContainer = () => {
      component.mainContainer.classList.add('hide');
      component.thankYouContainer.classList.remove('hide');
    }

    component.errorHandler = () => {
      component.failureContainer.classList.remove('hide');
    }

    component.addGRecaptcha = (element) => {
      element.classList.add("g-recaptcha");
    }

    component.loadOutOfStockNotification = (productId, outOfStockProduct) => {
      component.productId = productId;
      const form = component.element && component.element.querySelector(component.config.selectors.form);
      if (outOfStockProduct && !form) {
        app.ajax.get({
          url: `/waitlistSignUp.account`,
          success: component.loadOutOfStockNotificationSuccessHandler,
          error: component.loadOutOfStockNotificationErrorHandler
        });
      }

      if (!outOfStockProduct && form) {
        form && component.element.removeChild(form);
      }
    }

    component.loadOutOfStockNotificationSuccessHandler = (response) => {
      const wrapper= document.createElement('div');
      wrapper.innerHTML= response;
      const form = wrapper.querySelector(component.config.selectors.form);
      let loggedIn = form && form.getAttribute('data-logged-in');
      if (loggedIn !== 'true') {
        component.element && component.element.insertAdjacentHTML('afterbegin', response);
        component.init(component.element, true, component.productId);
      }
    }

    component.loadOutOfStockNotificationErrorHandler = (error) => {
      console.log(error);
    }

    return component;
  };
  return outOfStockNotification;
});
